import vueRouter from 'vue-router'
import Vue from 'vue';
import yuesao from '@/components/appointment/yuesao_order';
import index from '@/components/index'
import appointmentComm from '@/components/appointment/appointmentComm'
import applyPerson from '@/components/appointment/applyPerson'
import organization from '@/components/appointment/organization'
import organizationbenshi from '@/components/appointment/organizationbenshi'

Vue.use(vueRouter)

const routers = [
    {
        path: '/',
        component: index,
        meta:{
            title:'阳光家政'
        }
    },
    {
        path:  '/appointment/nanny',
        component: yuesao
    },
    {
        path:  '/index',
        component: index
    },
    {
        path:  '/appointment/comm',
        component: appointmentComm
    },
    {
        path:  '/appointment/applyPerson',
        component: applyPerson
    },
    {
        path:  '/appointment/organization',
        component: organization
    },
    {
        path:  '/appointment/thisCity',
        component: organizationbenshi
    }
] 
const router = new vueRouter(
    {
        routes:routers
    }
)

export default router