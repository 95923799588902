export default {
	// mode: 'test',
	baseUrl: 'https://ygdjonline.com/miniservice',
	// baseUrl: 'http://192.168.3.17:8081/sun-miniapp-api/',
	//baseUrl: 'http://140.143.209.146:8080/sun-miniapp-api', //测试
	// baseUrl: 'https://test.jnlysz.com/sun-miniapp-api', //测试
	ossUrl: 'https://ygdjonline.com/',
	picUrl: 'https://ygdjonline.com/uploads/sun_miniapp/image',
	maternityUrl: 'https://ygdjonline.com/thirdpart/',
	// maternityUrl: 'http://192.168.3.17:8003/', //刘士卡
	// maternityUrl: 'http://140.143.209.146:8003/',  //测试
	// maternityUrl: 'https://test.jnlysz.com/openapi/',  //测试
	meetingUrl: 'https://ygdjonline.com/meeting/',
	// meetingUrl: 'http://192.168.3.19:8080',
	meetingSMSUrl: 'https://ygdjonline.com/conferencesms/',
	s_img: '!m195x240.jpg',
	L_img: '!m750x280.jpg',
	remoteImgPath: 'https://ygdjonline.com/uploads/sun_miniapp/image/orginpic/',
	imgPathUrl:'https://ygdjonline.com/',
	storeProvinceArr : ['山东省', '山西省', '内蒙古', '河南省', '四川省', '江苏省', '黑龙江省', '湖北省', '湖南省'],
	storeCityArr: [
	    // 山东省
	    ['济南市', '聊城市', '日照市', '泰安市', '济宁市', '东营市', '烟台市', '德州市', '临沂市', '枣庄市', '滨州市', '淄博市', '潍坊市', '菏泽市', '青岛市', '威海市'],
	    // 山东省
	    ['晋城市'],
	    // 内蒙古
	    ['乌兰浩特市', '赤峰市'],
	    // 河南省
	    ['驻马店市', '郑州市'],
	    // 四川省
	    ['成都市'],
	    // 江苏省
	    ['宿迁市'],
	    // 黑龙江省
	    ['哈尔滨市'],
	    // 湖北省
	    ['襄阳市'],
	    // 湖南省
	    ['湘西自治州']
	]
}