<template>
  <div> 
      <div style="backgroundColor:#E51C23;height:44px;color:white;line-height:44px;" >   <!-- 顶部--> 
         <div style="margin-left:5px;" ><van-icon  @click="loadBack()" size="20" name="arrow-left" /> 加盟店</div>
      </div>
        <div class="bodyCss" >
            <div v-for="(item, index) in directStorelist"  style="padding-left:6px;" :key="index">
                        <div class="row-title">{{item.storeName}}</div>
                        <div class="row-container"
                            style="color: rgba(56, 56, 56, 1);"
                        >
                            <img class="col-img" mode="aspectFit" src="@/assets/phone.png" />
                            <div v-for="(ph, i) in item.phone" :key="i" @tap="() => phoneCall(ph)">{{ph}}{{item.phone.length > 1 && i == 0 ? '、' : ''}}</div>
                        </div>
                        <div class="row-container">
                            <img class="col-img" mode="aspectFit" src="@/assets/location.png" />
                            <div>
                             {{item.address}}
                            </div>
                        </div>
                        <div class="hr-div"></div>
            </div>
            <div style="height:10px;" ></div>
        </div>
  </div>
</template>

<script>
import config from "@/common/config";
import {getStoreList} from '@/common/api.js'
export default {
    data(){
        return {
            phoneicon: `${config.remoteImgPath}phone.png`,
            positionicon: `${config.remoteImgPath}position.png`,
            directStorelist:[],
            provinceArr:config.storeProvinceArr,
            storeCityArr:config.storeCityArr,
            showPickerCity: false,
            cityArrs: [],
            active:0,
            province:'请选择省份',
            city:'请选择地市',
            storeNum:'0',
            showPickerCityTwo: false,
        }
    },
    mounted(){
        console.log('------------')
        this.loadStoreListType(1);
        //this.directStorelist=[{province:'山东省',city:'聊城市',storeName:'阳光大姐总店',phone:['0531-12343'],address:'山东济南市山东济南市山东济南市山东济南市山东济南市山东济南市'}] 
    },
    methods:{
        loadStoreList(){
            getStoreList({
                province: this.province,
                city: this.city,
                zone: '',
                type: 2
                }
            ).then(response => {
                    console.log(response)
                if (response.status == 1) {
                    this.directStorelist = response.data.map(item => {
                        item.phone = item.phone.split('、')
                        return item
                    });
                }
            });   
        },
        loadStoreListType(type){
            getStoreList({
                province: '',
                city: '',
                zone: '',
                type: type
                }
            ).then(response => {
                    console.log(response)
                if (response.status == 1) {
                    this.directStorelist = response.data.map(item => {
                        item.phone = item.phone.split('、')
                        return item
                    });
                    if(type==2){
                        this.storeNum =  this.directStorelist.length;
                    }
                }
            }); 
        },
        onConfirmPiCity(value,index){
            this.province =value;
             this.city = "";
            this.cityArrs = this.storeCityArr[index];
            this.showPickerCity = false;
            this.loadStoreList()
        },
        onConfirmPiCityTwo(value,index){
            this.city = value;
            this.showPickerCityTwo = false;
            this.loadStoreList();
        },
        onClick(name, title){
            console.log(name,title)
            if(name ==0){
               this.loadStoreListType(1);
            }else {
               this.loadStoreListType(2);
            }
        },
        loadBack(){
             this.$router.go(-1)
        },
    }
}
</script>

<style>
.row-title {
    padding: 10px 10px;
    font-size: 1.0rem;
    color: rgba(56, 56, 56, 1);
    font-family:math;
}
.row-container {
    display: flex;
    align-items: center;
    font-size: 1.0rem;
    margin-right: 15px;
    margin-left: 1px;
}
.col-img {
    width: 1rem;
    height: 1rem;
    flex-shrink: 0;
}
.hr-div {
    margin-top: 18px;
    background-color: #eee;
    height: 1px;
    padding-left:14px;
    padding-right:14px;
    width:90%;
}
.picker-container {
    display: flex;
    height: 60px;
    align-items: center;
    padding-left: 10px;
    color: #999;
    border-bottom: 2px solid #eee;
}
.picker{
    width: 30px;
}
    .labCss{
        font-size: 15px;
        color: rgba(0, 0, 0, 1);
        font-weight: 600;
    }
    .bodyCss{
        border: 1px solid rgba(232, 204, 204, 0.25);
        margin: 12px;
        box-shadow: 0 0 2px 1px rgba(232, 204, 204, 0.25);
    }
</style>
