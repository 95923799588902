<template>
  <div> 
      <div style="backgroundColor:#E51C23;height:44px;color:white;line-height:44px;" >   <!-- 顶部--> 
         <div style="margin-left:15px;" > <van-icon  @click="loadBack()" size="20" name="arrow-left" />加盟店</div>
      </div>
      <div class="bodyCss" >
          <van-tabs line-width="5rem"  @click="onClick" title-active-color="#ee0a24" v-model="active">
            <van-tab title="直营分店"  title-style="font-size:16px;" >
                <div  >
                    <div style="padding-left:6px;" v-for="(item, index) in directStorelist" :key="index">
                        <div class="row-titleks">{{item.storeName}}</div>
                        <div class="row-container"
                            style="color: rgba(56, 56, 56, 1);"
                        >
                            <img class="col-imgo"  src="@/assets/phone.png" />
                            <div v-for="(ph, i) in item.phone" :key="i" class="contentCss" @tap="() => phoneCall(ph)">{{ph}}{{item.phone.length > 1 && i == 0 ? '、' : ''}}</div>
                        </div>
                        <div class="row-container">
                            <img class="col-imglo"  src="@/assets/location.png" />
                            <div class="contentCss" >
                                {{item.address}}
                            </div>
                        </div>
                        <div class="hr-div"></div>
                    </div>
                </div>
            </van-tab>
            <van-tab title="加盟机构" title-style="font-size:16px;" >
                    <van-popup v-model="showPickerCity" position="bottom">
                                <van-picker
                                    show-toolbar
                                    :columns="provinceArr"
                                    @confirm="onConfirmPiCity"
                                    @cancel="showPickerCity = false"
                                />
                    </van-popup>
                    <van-popup v-model="showPickerCityTwo" position="bottom">
                                <van-picker
                                    show-toolbar
                                    :columns="cityArrs"
                                    @confirm="onConfirmPiCityTwo"
                                    @cancel="showPickerCityTwo = false"
                                />
                    </van-popup>
                <div style="height:5px;" ></div>
                <div>
                    <van-row>
                    <van-col span="8">
                        <div style="margin-left:20px;" @click="showPickerCity = true"  >
                           {{province || '请选择地市'}}

                        </div>
                    </van-col>
                    <van-col span="8">
                         <div style="margin-left:20px;" @click="showPickerCityTwo = true" >
                            {{city || '请选择地市'}}

                        </div>
                    </van-col>
                    <van-col span="8">
                        <div class="store-count">
                            加盟店：{{storeNum}}个
                        </div>
                    </van-col>

                    </van-row>
                    
                    <van-divider :hairline="false" />
                    <div style="padding-left:6px;" v-for="(item, index) in directStorelist" :key="index">
                        <div class="row-titleks"><label style="font-weight: 500;">加盟区域：</label>{{item.province}}{{item.city}}</div>
                        <div class="row-container"
                            style="color: rgba(56, 56, 56, 1);"
                        >
                            <img class="col-imgo" mode="aspectFit" src="@/assets/phone.png" />
                            <div v-for="(ph, i) in item.phone" :key="i" class="contentCss" @tap="() => phoneCall(ph)">{{ph}}{{item.phone.length > 1 && i == 0 ? '、' : ''}}</div>
                        </div>
                        <div class="row-container">
                            <img class="col-imglo" mode="aspectFit"  src="@/assets/location.png" />
                            <div class="contentCss" >
                            {{item.address}}
                            </div>
                        </div>
                        <div class="hr-div"></div>
                    </div>
                </div>
            </van-tab>
        </van-tabs>
      </div>  
  </div>
</template>

<script>
import config from "@/common/config";
import {getStoreList} from '@/common/api.js'
export default {
    data(){
        return {
            phoneicon: './assets/phone.png',
            positionicon: './assets/location.png',
            directStorelist:[],
            provinceArr:config.storeProvinceArr,
            storeCityArr:config.storeCityArr,
            showPickerCity: false,
            cityArrs: [],
            active:0,
            province:'请选择省份',
            city:'请选择地市',
            storeNum:'0',
            showPickerCityTwo: false,
        }
    },
    mounted(){
        console.log('------------')
        this.loadStoreListType(1);
        //this.directStorelist=[{province:'山东省',city:'聊城市',storeName:'阳光大姐总店',phone:['0531-12343'],address:'山东济南市山东济南市山东济南市山东济南市山东济南市山东济南市'}] 
    },
    methods:{
        loadStoreList(){
            getStoreList({
                province: this.province,
                city: this.city,
                zone: '',
                type: 2
                }
            ).then(response => {
                    console.log(response)
                if (response.status == 1) {
                    this.directStorelist = response.data.map(item => {
                        item.phone = item.phone.split('、')
                        return item
                    });
                }
            });   
        },
        loadBack(){
             this.$router.go(-1)
        },
        loadStoreListType(type){
            getStoreList({
                province: '',
                city: '',
                zone: '',
                type: type
                }
            ).then(response => {
                    console.log(response)
                if (response.status == 1) {
                    this.directStorelist = response.data.map(item => {
                        item.phone = item.phone.split('、')
                        return item
                    });
                    if(type==2){
                        this.storeNum =  this.directStorelist.length;
                    }
                }
            }); 
        },
        onConfirmPiCity(value,index){
            this.province =value;
             this.city = "";
            this.cityArrs = this.storeCityArr[index];
            this.showPickerCity = false;
            this.loadStoreList()
        },
        onConfirmPiCityTwo(value,index){
            this.city = value;
            this.showPickerCityTwo = false;
            this.loadStoreList();
        },
        onClick(name, title){
            console.log(name,title)
            if(name ==0){
               this.loadStoreListType(1);
            }else {
               this.loadStoreListType(2);
            }
        }
    }
}
</script>

<style>
.row-titleks {
    padding: 10px 10px;
    font-weight:500;
    font-size: 1.04rem;
}
.row-container {
    display: flex;
    align-items: center;
    font-size: 1.0rem;
    margin-right: 15px;
    margin-left: 1px;
}
.col-imgo {
    width: 1.0rem;
    height: 1.0rem;
    flex-shrink: 0;
}
.col-imglo {
    width: 1.0rem;
    height: 1.2em;
    flex-shrink: 0;
}
.hr-div {
    margin-top: 18px;
    background-color: rgba(166, 166, 166, 1);
    height: 1px;
    margin-left:2%;
    width:92%;
}
.contentCss{
    margin-left:6px;
}
.bodyCss{
    border: 1px solid rgba(232, 204, 204, 0.25);
    margin: 12px;
    box-shadow: 0 0 2px 1px rgba(232, 204, 204, 0.25);
}
.picker-container {
    display: flex;
    height: 60px;
    align-items: center;
    padding-left: 10px;
    color: #999;
    border-bottom: 2px solid #eee;
}
.picker{
    width: 30px;
}
</style>
