<template>
  <div> 
      <div style="height:44px;line-height:44px;font-size:17px;font-weight:550;" >   <!-- 顶部--> 
         <div style="margin-left:5px;" > <van-icon  @click="loadBack()" size="20" name="arrow-left" />培训报名</div>
      </div>
      <div class="bodyCss">
        <van-form @submit="onSubmit">
            <!-- 公共部分start-->
            <van-field
                v-model="formData.name"
                name="用户姓名"
                label="用户姓名"
                placeholder="请填写用户名"
                label-class="labCss"
                input-align="right"
                :rules="[{ required: true, message: '请填写用户名' }]"
            />
            <van-field
                v-model="formData.phone"
                name="电话"
                label="电话"
                placeholder="请填写电话"
                label-class="labCss"
                input-align="right"
                :rules="[{ required: true, message: '请填写电话' }]"
            />
            <van-field
                v-model="formData.detailedAddress"
                name="地址"
                label="地址"
                label-class="labCss"
                input-align="right"
                placeholder="请填写地址"
                :rules="[{ required: true, message: '请填写地址' }]"
                  />
            <!--公共部分end --> 
            <van-field
                v-model="formData.cardid"
                name="身份证号"
                label="身份证号"
                label-class="labCss"
                input-align="right"
                placeholder="请填写身份证号"
                  />
            <van-field
                readonly
                clickable
                name="picker"
                :value="formData.serviceType"
                label-class="labCss"
                input-align="right"
                label="服务类型"
                right-icon="arrow"
                placeholder="请选择服务类型"
                @click="showPickerXj = true"
            />
            <van-popup v-model="showPickerXj" position="bottom">
            <van-picker
                show-toolbar
                :columns="columnsXj"
                @confirm="onConfirmPicXj"
                @cancel="showPickerXj = false"
            />
            </van-popup>

            <!-- 按钮点击-->
            <van-field
                v-model="formData.smsCode"
                name="验证码"
                label="验证码"
                placeholder="验证码"
                label-class="labCss"
                input-align="right"
                :rules="[{ required: true, message: '请填写验证码' }]"
            >
             <template #button>
                <van-button size="small"  @click="showDialogView" color="rgba(247, 102, 79, 1)" plain type="primary">发送验证码</van-button>
            </template>
            </van-field>
            <van-dialog v-model="showDialog" title="图片验证" @confirm="confirmDialog" show-cancel-button>
                <div style="text-align:center;">
                    <img style="height:36px;width:125px;" :src="imgUrl" />
                    <van-cell-group>
                        <van-field :border="true" v-model="imgCode" label="验证码:" />
                    </van-cell-group>
                </div>
            </van-dialog>
            
        </van-form>
      </div>
      <div style="margin: 16px;">
            <van-button color="rgba(247, 102, 79, 1)" round style="height:42px;" @click="sumibtForm" size="large" >提交</van-button>
      </div>
  </div>
</template>

<script>
import {getCode,addHire,smsSenderCode} from '@/common/api.js'
import { Toast } from 'vant';

export default {
    data(){
        return {
            formData:{
                name:'',
                phone:'',
                smsCode:'',
                detailedAddress:'',
                cardid:'',
                ycdata:'',
                fwdata:'',
                serviceType:'',
                storeId:'12343',
                cityName:' ',
                provinceName:'',
                zoneName:'',
            },
            switchChecked:false,
            showCalendar:false,
            showPicker:false,
            showPickerXj:false,
            showDialog:false,
            imgCode:'',
            imgUrl:'',
            columnsXj: ['专业月嫂', '家政服务', '医院陪护', '专业保洁']
        }
    },
    methods:{
        onSubmit(values) {
            console.log('submit', values);
        },
        onConfirm(date){
            console.log(date)
            this.formData.ycdata = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
            this.showCalendar = false;
        },
        onConfirmPic(value, index) {
            this.showPicker = false;
            this.formData.fwdata = value;
            console.log(value,index)
        },
        onConfirmPicXj(value, index) {
            this.showPickerXj = false;
            this.formData.serviceType = value;
            console.log(value,index)
        },
        showDialogView(){
          getCode().then(response => {
            //console.log(response.data.img);
            let blob = new Blob([response],{type: "image/png"});
            this.imgUrl = window.URL.createObjectURL(blob);
          });
            this.showDialog = true;
        },
        loadBack(){
             this.$router.go(-1)
        },
        confirmDialog(){
            //发送验证码请求
            //验证码校验通过后，发送获取短信请求
            console.log('我是dialog回调',this.imgCode)
            smsSenderCode({code:this.imgCode,phone:this.formData.phone}).then(response => {
                console.log(response);
                if(response.status == 1){
                    Toast('短信验证码发送成功！');
                }else {
                    Toast(response.message);
                }
            });
        },
        sumibtForm(){
            if(this.formData.name=='' || this.formData.phone=='' || this.formData.detailedAddress==''){
                Toast('请检查必须项！');
                return ;
            }
            addHire(this.formData).then(response => {
                console.log(response);
                if(response.status == 1){
                    Toast('报名成功!');
                    setTimeout(() => {
                        this.$router.push({path:'/index'})
                    },500)
                    // this.$router.push({path:'/index'})
                }else {
                    Toast(response.message);
                }
            });

        }
    }
}
</script>

<style>
    .labCss{
        font-size: 15px;
        color: rgba(0, 0, 0, 1);
        font-weight: 600;
    }
    .bodyCss{
        border: 1px solid rgba(224, 200, 200, 0.36);
        margin: 12px;
        box-shadow: 0 0 2px 1px rgba(224, 200, 200, 0.36);
    }
</style>
