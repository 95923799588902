<template>
  <div> 
      <div style="height:44px;color:rgba(0, 0, 0, 1);line-height:44px;font-size:17px;font-weight:550;" >   <!-- 顶部--> 
         <div style="" > <van-icon  @click="loadBack()" size="20" name="arrow-left" />专业月嫂</div>
      </div>
      <div class="bodyCss"  >
        <van-form @submit="onSubmit">
            <!-- 公共部分start-->
            <van-field
                v-model="formData.name"
                name="用户姓名"
                label="用户姓名"
                placeholder="请填写用户名"
                label-class="labCss"
                input-align="right"
                :rules="[{ required: true, message: '请填写用户名' }]"
            />
            <van-field
                v-model="formData.phone"
                name="电话"
                label="电话"
                label-class="labCss"
                input-align="right"
                placeholder="请输入电话"
                :rules="[{ required: true, message: '请填写电话' }]"
            />
            <van-field
                v-model="formData.detailedAddress"
                name="地址"
                label="地址"
                label-class="labCss"
                input-align="right"
                placeholder="请输入地址"
                :rules="[{ required: true, message: '请填写地址' }]"
                  />
            <!--公共部分end --> 

           <van-field name="switch"
            label-class="labCss"
            input-align="right"
            label="新生儿">
                <template #input>
                    <van-switch v-model="switchChecked" size="20" />
                </template>
            </van-field> 

            
            <van-field
                readonly
                clickable
                name="picker"
                :value="formData.babySexKey"
                label="性别"
                placeholder="性别"
                label-class="labCss"
                input-align="right"
                right-icon="arrow"
                @click="sexPicker = true"
                v-if="!switchChecked"
            />
            <van-popup v-model="sexPicker" position="bottom">
            <van-picker
                show-toolbar
                :columns="sexList"
                @confirm="onConfirmPicSex"
                @cancel="sexPicker = false"
            />
            </van-popup>


            <van-field
                readonly
                clickable
                name="picker"
                label-class="labCss"
                input-align="right"
                :value="formData.babyMonthsAge"
                label="宝贝年龄"
                placeholder="宝贝年龄"
                right-icon="arrow"
                @click="arraytowPicker = true"
                v-if="!switchChecked"
            />
            <van-popup v-model="arraytowPicker" position="bottom">
            <van-picker
                show-toolbar
                :columns="arraytow"
                @confirm="onConfirmPicArraytow"
                @cancel="arraytowPicker = false"
            />
            </van-popup>

           <van-field v-if="!switchChecked" label-class="labCss" name="switch" right-icon="arrow" label="服务时间">
                <template #input>
                    <van-field readonly	@click="showPickerData = true" v-model="formData.serviceBeginTime" placeholder="请选择" />
                        至
                    <van-field readonly @click="showPickerDataEnd = true" v-model="formData.serviceEndTime" placeholder="请选择" />
                    <van-popup v-model="showPickerData" position="bottom">
                                <van-datetime-picker
                                type="date"
                                v-model="currentDate"
                                title="选择年月日"
                                @confirm="onConfirmPicDate"
                                :min-date="minDate"
                                :max-date="maxDate"
                                />
                    </van-popup>
                    <van-popup v-model="showPickerDataEnd" position="bottom">
                                <van-datetime-picker
                                type="date"
                                v-model="currentDateEnd"
                                title="选择年月日"
                                @confirm="onConfirmPicDataEnd"
                                :min-date="minDate"
                                :max-date="maxDate"
                                />
                    </van-popup>
                </template>

            </van-field> 


            <van-field
                readonly
                clickable
                name="calendar"
                label-class="labCss"
                input-align="right"
                :value="formData.babyExpectedDate"
                label="预产日期"
                placeholder="点击选择日期"
                @click="showCalendar = true"
                v-if="switchChecked"
                right-icon="arrow"
            />
            <van-calendar v-model="showCalendar" @confirm="onConfirm" />

            <van-field
                readonly
                clickable
                label-class="labCss"
                input-align="right"
                name="picker"
                :value="formData.newbabyServiceTimeKey"
                label="服务日期"
                placeholder="请选择服务日期"
                @click="showPicker = true"
                v-if="switchChecked"
                right-icon="arrow"
            />
            <van-popup v-model="showPicker" position="bottom">
            <van-picker
                show-toolbar
                :columns="columns"
                @confirm="onConfirmPic"
                @cancel="showPicker = false"
            />
            </van-popup>

            <van-field
                readonly
                clickable
                label-class="labCss"
                input-align="right"
                name="picker"
                :value="formData.serviceLevel"
                label="服务星级"
                placeholder="请选择服务星级"
                @click="showPickerXj = true"
                right-icon="arrow"
            />
            <van-popup v-model="showPickerXj" position="bottom">
            <van-picker
                show-toolbar
                :columns="columnsXj"
                @confirm="onConfirmPicXj"
                @cancel="showPickerXj = false"
            />
            </van-popup>

            <!-- 按钮点击-->
            <van-field
                v-model="formData.smsCode"
                name="验证码"
                label-class="labCss"
                label="验证码"
                placeholder="验证码"
                :rules="[{ required: true, message: '请填写验证码' }]"
                input-align="right"
            >
             <template #button>
                <van-button size="small"  @click="showDialogView" plain  color="rgba(255, 99, 64, 1)" type="primary">发送验证码</van-button>
            </template>
            </van-field>
            <van-dialog v-model="showDialog" title="图片验证" @confirm="confirmDialog" show-cancel-button>
                <div style="text-align:center;">
                    <img style="height:36px;width:125px;" :src="imgUrl" />    
                    <van-cell-group>
                    <van-field :border="true" v-model="imgCode" label="验证码:" />
                    </van-cell-group>
                </div>
            </van-dialog>
            

        </van-form>
      </div>
      <div style="height:8px;" ></div>
      <div style="margin: 16px;">
               
                <van-button color="rgba(247, 102, 79, 1)" style="height:42px;" @button-large-height="40" @click="sumibtForm" round size="large" >提交</van-button>
      </div>
  </div>
</template>

<script>
import {getCode,smsSenderCode,createOrderWebpage} from '@/common/api.js'
import { Toast } from 'vant';

export default {
    data(){
        return {
            formData:{
                customerId:'1',
                goodsId:'1',
                isBaby:'',
                name:'',
                phone:'',
                smsCode:'',
                babySexKey:'',
                babySex:'',
                detailedAddress:'',
                babyExpectedDate:'',
                newbabyServiceTimeKey:'',
                babyMonthsAge:'',
                newbabyServiceTime:'',
                serviceLevel:'',
                goodsName:'月嫂类',
                goodsType:'1',
                serviceBeginTime:'',
                serviceEndTime:'',
            },
            showPickerData:false,
            showPickerDataEnd:false,
            minDate: new Date(2020, 0, 1),
            maxDate: new Date(2025, 10, 1),
            currentDate: new Date(),
            currentDateEnd:new Date(),
            switchChecked:true,
            showCalendar:false,
            showPicker:false,
            showPickerXj:false,
            showDialog:false,
            imgCode:'',
            imgUrl:'',
            columns: ['一个月', '二个月', '三个月', '四个月', '五个月','六个月'],
            columnsXj: ['首席技师', '首席金牌', '金牌月嫂', '星级月嫂'],
            sexList:["女","男"],
            sexPicker:false,
            arraytow: ["0-3个月", "3-6个月", "6-12个月", "1-3岁", "3岁以上"],
            arraytowPicker: false
        }
    },
    methods:{
        onSubmit(values) {
            console.log('submit', values);
        },
        onConfirm(date){
            console.log(date)
            this.formData.babyExpectedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
            this.showCalendar = false;
        },
        onConfirmPic(value, index) {
            this.showPicker = false;
            this.formData.newbabyServiceTimeKey = value;
            console.log(value,index)
        },
        onConfirmPicXj(value, index) {
            this.showPickerXj = false;
            this.formData.serviceLevel = value;
            console.log(value,index)
        },
        onConfirmPicSex(value,index){
            this.formData.babySexKey = value;
            this.formData.babySex = index;

            this.sexPicker = false ;
        },
        onConfirmPicArraytow(value){
            this.formData.babyMonthsAge = value;
            this.arraytowPicker = false ;
        },
        onConfirmPicDataEnd(date) {
            this.showPickerDataEnd = false;
            this.formData.serviceEndTime = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
        },
        onConfirmPicDate(date) {
            this.showPickerData = false;
            this.formData.serviceBeginTime = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
            //this.formData.ksrq = date;
            console.log(date)
        },
        showDialogView(){
          this.imgCode = '';  
          getCode().then(response => {
            //console.log(response.data.img);
            let blob = new Blob([response],{type: "image/png"});
            this.imgUrl = window.URL.createObjectURL(blob);
          });
            this.showDialog = true;
        },
        confirmDialog(){
            //发送验证码请求
            //验证码校验通过后，发送获取短信请求
            console.log('我是dialog回调',this.imgCode)
            smsSenderCode({code:this.imgCode,phone:this.formData.phone}).then(response => {
                if(response.status == 1){
                    Toast('短信验证码发送成功！');
                }else {
                     Toast(response.message);
                }
            });
        },
        loadBack(){
             this.$router.go(-1)
        },
        sumibtForm(){
            console.log(this.formData.serviceBeginTime,this.formData.serviceEndTime)
            if(this.formData.serviceBeginTime > this.formData.serviceEndTime){
                    Toast('开始时间不可大于结束时间！');
                    return ;
            }
            if(this.formData.name=='' || this.formData.phone=='' || this.formData.detailedAddress==''){
                Toast('请检查必须项！');
                return ;
            }
            if(this.switchChecked){
                    this.formData.isBaby = 1;  //新生儿
            }else {
                 this.formData.isBaby = 0;
            }
            let threeold = 1;
            switch (this.formData.newbabyServiceTimeKey) {
                case "1个月":
                    threeold = 1;
                    break;
                case "2个月":
                    threeold = 2;
                    break;
                case "3个月":
                    threeold = 3;
                    break;
                case "4个月":
                    threeold = 4;
                    break;
                case "5个月":
                    threeold = 5;
                    break;
                case "6个月":
                    threeold = 6;
                    break;
            }
            this.formData.newbabyServiceTime = threeold;
            createOrderWebpage(this.formData).then(response => {
                 
                 if(response.status==1){
                    Toast('预约成功！');
                    setTimeout(() => {
                        this.$router.push({path:'/index'})
                    },500)
                 }else {
                     Toast(response.message);
                 }
                console.log(response);
               // this.$router.push({path:'/index'})
            });
           

            
        }
    }
}
</script>

<style>
    .labCss{
        font-size: 15px;
        color: rgba(0, 0, 0, 1);
        font-weight: 600;
    }
    .bodyCss{
        border: 1px solid rgba(224, 200, 200, 0.36);
        margin: 12px;
        box-shadow: 0 0 2px 1px rgba(224, 200, 200, 0.36);
    }
</style>
