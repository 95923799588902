import axios from 'axios'

const service = axios.create({
    baseURL: 'https://ygdjonline.com/miniservice/', // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 5000 // request timeout
  })

  service.interceptors.request.use(
    config => {
      // do something before request is sent
      console.log(config);
      if(config.url.includes('/information/queryList')){
        config.baseURL = 'https://ygjz.jnlysz.com/';
      }else {
        config.baseURL = 'https://ygdjonline.com/miniservice/';
      }
      // if(config.headers[0]==1){
      //   delete config.headers[0]
      //   config.baseURL = 'https://testwxmp.ygdjonline.com/productHotController/getProductHotInfo/1297'
      // }
      return config
    },
    error => {
      // do something with request error
      console.log(error) // for debug
      return Promise.reject(error)
    }
  )


  // response interceptor
service.interceptors.response.use(
    response => {
      const res = response.data     
      // if the custom code is not 20000, it is judged as an error.
      if (response.status != 200) {
        // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
        if (res.code === 401 || res.code === 50012 || res.code === 50014) {
          // to re-login
        }
        return Promise.reject(new Error(res.msg ||'Error 状态码不为0'))
      } else {
        return res
      }
    },
    error => {
      console.log('err-------' + error) // for debug
      return Promise.reject(error)
    }
  )

  export default service