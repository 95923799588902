import Vue from 'vue'
import App from './App.vue'
import { Swipe, SwipeItem } from 'vant';
import { Lazyload } from 'vant';
import { Image as VanImage } from 'vant';
import { List } from 'vant';
import { Cell} from 'vant';
import { Form } from 'vant';
import { Field } from 'vant';
import { Button } from 'vant';
import { Switch } from 'vant';
import { Calendar } from 'vant';
import { Picker } from 'vant';
import { Popup } from 'vant';
import { Dialog } from 'vant';
import { Toast } from 'vant';
import { CellGroup } from 'vant';
import { DatetimePicker } from 'vant';
import { Tab, Tabs } from 'vant';
import { Col, Row } from 'vant';
import { Divider } from 'vant';
import { Icon } from 'vant';
import { Grid, GridItem } from 'vant';
import 'vant/lib/index.css';
import router from './router'

Vue.config.productionTip = false
Vue.use(Popup);
Vue.use(Button);
Vue.use(Picker);
Vue.use(Switch);
Vue.use(Calendar);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Lazyload);
Vue.use(VanImage);
Vue.use(List);
Vue.use(Cell);
Vue.use(Form);
Vue.use(Toast);
Vue.use(Field);
Vue.use(Dialog);
Vue.use(CellGroup);
Vue.use(DatetimePicker);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Col);
Vue.use(Row);
Vue.use(Divider);
Vue.use(Icon);
Vue.use(Grid);
Vue.use(GridItem);
new Vue({
  render: h => h(App),
  router
}).$mount('#app')

