<template>
  <div> 
      <div style="height:44px;line-height:44px;font-size:17px;font-weight:550;" >   <!-- 顶部--> 
         <div style="margin-left:5px;" ><van-icon  @click="loadBack()" size="20" name="arrow-left" /> {{title}}</div>
      </div>
      <div class="bodyCss" >
        <van-form @submit="onSubmit">
            <!-- 公共部分start-->
            <van-field
                v-model="formData.name"
                name="用户姓名"
                label="用户姓名"
                placeholder="请填写用户名"
                label-class="labCss"
                input-align="right"
                :rules="[{ required: true, message: '请填写用户名' }]"
            />
            <van-field
                v-model="formData.phone"
                name="电话"
                label="电话"
                label-class="labCss"
                input-align="right"
                placeholder="请输入电话"
                :rules="[{ required: true, message: '请填写电话' }]"
            />
            <van-field
                v-model="formData.detailedAddress"
                name="地址"
                label="地址"
                label-class="labCss"
                input-align="right"
                placeholder="请输入地址"
                :rules="[{ required: true, message: '请填写地址' }]"
                  />
            <!--公共部分end --> 
            <van-field
                readonly
                clickable
                name="picker"
                :value="formData.goodsName"
                label="服务项目"
                placeholder="服务项目"
                label-class="labCss"
                input-align="right"
                right-icon="arrow"
                @click="servicePicker = true"
            />
            <van-popup v-model="servicePicker" position="bottom">
            <van-picker
                show-toolbar
                :columns="serviceList"
                @confirm="onConfirmPicSex"
                @cancel="servicePicker = false"
            />
            </van-popup>


            <van-field
                readonly
                clickable
                name="picker"
                :value="formData.serviceBeginTime"
                label-class="labCss"
                input-align="right"
                right-icon="arrow"
                label="开始日期"
                placeholder="请选择服务日期"
                @click="showPicker = true"
            />
            <van-popup v-model="showPicker" position="bottom">
                <van-datetime-picker
                type="date"
                v-model="currentDate"
                title="选择年月日"
                label-class="labCss"
                input-align="right"
                right-icon="arrow"
                @confirm="onConfirmPic"
                :min-date="minDate"
                :max-date="maxDate"
                />
            </van-popup>

            <van-field
                readonly
                clickable
                name="picker"
                label-class="labCss"
                input-align="right"
                right-icon="arrow"
                :value="formData.serviceEndTime"
                label="结束日期"
                placeholder="请选择服务日期"
                @click="showPickerXj = true"
            />
            <van-popup v-model="showPickerXj"  position="bottom">
                <van-datetime-picker
                v-model="currentDateEnd"
                type="date"
                label-class="labCss"
                input-align="right"
                right-icon="arrow"
                title="选择年月日"
                @confirm="onConfirmPicXj"
                :min-date="minDate"
                :max-date="maxDate"
                />
            </van-popup>


            <van-field
                readonly
                clickable
                name="datetimePicker"
                label-class="labCss"
                input-align="right"
                right-icon="arrow"
                :value="formData.serviceDayBeginTime"
                label="开始时间"
                placeholder="请选择服务时间"
                @click="showTimePicker = true"
            />
            <van-popup v-model="showTimePicker" position="bottom">
            <van-datetime-picker
                type="time"
                @confirm="onConfirmTimePacker"
                @cancel="showTimePicker = false"
            />
            </van-popup>

            <van-field
                readonly
                clickable
                name="datetimePicker"
                label-class="labCss"
                input-align="right"
                right-icon="arrow"
                :value="formData.serviceDayEndTime"
                label="结束时间"
                placeholder="请选择服务时间"
                @click="showTimePickerEnd = true"
            />
            <van-popup v-model="showTimePickerEnd" position="bottom">
            <van-datetime-picker
                type="time"
                @confirm="onConfirmTimePackerEnd"
                @cancel="showTimePickerEnd = false"
            />
            </van-popup>


            <!-- 按钮点击-->
            <van-field
                v-model="formData.smsCode"
                name="验证码"
                label="验证码"
                label-class="labCss"
                input-align="right"
                placeholder="验证码"
                :rules="[{ required: true, message: '请填写验证码' }]"
            >
             <template #button>
                <van-button size="small" color="rgba(247, 102, 79, 1)" plain  @click="showDialogView" type="primary">发送验证码</van-button>
            </template>
            </van-field>
            <van-dialog v-model="showDialog" title="图片验证" @confirm="confirmDialog" show-cancel-button>
                <div style="text-align:center;">
                    <img style="height:36px;width:125px;" :src="imgUrl" />
                    <van-cell-group>
                        <van-field :border="true" v-model="imgCode" label="验证码:" />
                    </van-cell-group>
                </div>
            </van-dialog>
            

        </van-form>
      </div>
                  <div style="margin: 16px;">
                <van-button color="rgba(247, 102, 79, 1)" round style="height:42px;" @click="sumibtForm" size="large" >提交</van-button>
        </div>
  </div>
</template>

<script>
import {getCode,smsSenderCode,createOrderWebpage,getGoodsList} from '@/common/api.js'
import { Toast } from 'vant';

export default {
    data(){
        return {
            formData:{
                goodsId:'',
                name:'',
                phone:'',
                smsCode:'',
                goodsName:'',
                detailedAddress:'',
                serviceBeginTime:'',
                serviceEndTime:'',
                serviceDayBeginTime:'',
                serviceDayEndTime:'',
                goodsTypeCode:'',
            },
            servicePicker:false,
            serviceList:[],
            resultServiceList:[],
            title: this.$route.query.title,
            minDate: new Date(2020, 0, 1),
            maxDate: new Date(2025, 10, 1),
            currentDate: new Date(),
            currentDateEnd:new Date(),
            switchChecked:false,
            showCalendar:false,
            showPicker:false,
            showPickerXj:false,
            showTimePicker:false,
            showTimePickerEnd:false,
            showDialog:false,
            imgCode:'',
            imgUrl:'',
            columns: ['一个月', '二个月', '三个月', '四个月', '五个月','六个月'],
            columnsXj: ['首席技师', '首席金牌', '金牌月嫂', '星级月嫂'],
            type: this.$route.query.type,
        }
    },
    mounted(){
        this.loadGoodsList()
    },
    methods:{
        loadGoodsList(){
            let data ={};
            
            data.sortType=1;
            data.miniserviceId=this.$route.query.type;
    
            console.log('data',data)
            getGoodsList(data).then(response => {
                    console.log(response);
                    this.resultServiceList = response.data.list;
                    response.data.list.map(d => {
                        this.serviceList.push(d.goodsName);
                    });
            });
        },
        onConfirmPicSex(value,index){
           let item = this.resultServiceList[index];
           this.formData.goodsId = item.goodsId;
           this.formData.goodsTypeCode =item.goodsTypeCode;
           this.formData.goodsName = value;
           this.servicePicker =false;
           console.log(value,index);
        },
        loadBack(){
             this.$router.go(-1)
        },
        onSubmit(values) {
            console.log('submit', values);
        },
        onConfirm(date){
            console.log(date)
            this.formData.ycdata = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
            this.showCalendar = false;
        },
        onConfirmPic(date) {
            this.showPicker = false;
            this.formData.serviceBeginTime = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
            //this.formData.serviceBeginTime = date;
            console.log(date)
        },
        onConfirmPicXj(date) {
            this.showPickerXj = false;
            this.formData.serviceEndTime = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
        },
        onConfirmTimePacker(value){
            this.showTimePicker =false;
            this.formData.serviceDayBeginTime = value;
        },
        onConfirmTimePackerEnd(value){
            this.showTimePickerEnd =false;
            this.formData.serviceDayEndTime = value;
        },
        showDialogView(){
          this.imgCode = '';  
          getCode().then(response => {
            let blob = new Blob([response],{type: "image/png"});
            this.imgUrl = window.URL.createObjectURL(blob);
          });
            this.showDialog = true;
        },
        confirmDialog(){
            //发送验证码请求
            //验证码校验通过后，发送获取短信请求
            smsSenderCode({code:this.imgCode,phone:this.formData.phone}).then(response => {
                console.log(response);
                if(response.status == 1){
                    Toast('短信验证码发送成功！');
                }else {
                    Toast(response.message);
                }
            });
        },
        sumibtForm(){
            if(this.formData.serviceBeginTime > this.formData.serviceEndTime){
                    Toast('开始时间不可大于结束时间！');
                    return ;
            }
            if(this.formData.name=='' || this.formData.phone=='' || this.formData.detailedAddress==''){
                Toast('请检查必须项！');
                return ;
            }
            createOrderWebpage(this.formData).then(response => {
                 if(response.status==1){
                    Toast('预约成功！');
                    setTimeout(() => {
                        this.$router.push({path:'/index'})
                    },500)
                 }else {
                    Toast(response.message);
                 }
               // this.$router.push({path:'/index'})
            });

        }
    }
}
</script>

<style>
    .labCss{
        font-size: 15px;
        color: rgba(0, 0, 0, 1);
        font-weight: 600;
    }
    .bodyCss{
        border: 1px solid rgba(224, 200, 200, 0.36);
        margin: 12px;
        box-shadow: 0 0 2px 1px rgba(224, 200, 200, 0.36);
    }
</style>
