<template>
  <div class="containers">
      <div style="backgroundColor:#E51C23;height:60px;color:white;line-height:60px;text-align:center;font-size:18px;font-weight:600;" >   <!-- 顶部--> 
         <div> 阳光大姐</div>
      </div>
      <div> <!--轮播图 -->
        <van-swipe :autoplay="3000">
          <van-swipe-item v-for="(item, index) in images" :key="index">
            <img style=" width: 100%;height:170px;" lazy-load :src="item.bannerImageUrl" />
          </van-swipe-item>
        </van-swipe>
      </div>

      <div> <!-- 首页模块 -->
          <div style="display:flex;" >  <!-- 月嫂、家政按钮 -->
              <div style="width:50%;" >
                <van-image
                 @click="
              jmpPage('专业月嫂', '/appointment/nanny')"
                src="http://ygdjonline.com/ywxt/sun_miniapp/image/20230412/827097c7-6573-4677-8691-9b74199e3cc5.png"
                />
              </div>
            <div style="width:50%;" >
              <van-image
                @click="
              jmpPage('家政服务', '/appointment/comm','2')
              "
                src="http://ygdjonline.com/ywxt/sun_miniapp/image/20230412/10343b72-1653-4e9b-be54-7032be7c1c10.png"
              />
            </div>
          </div>

          <div>       <!-- Grid 宫格按钮 -->
            <van-grid :column-num="4" :border="false"	>
              <van-grid-item                         @click="
              jmpPage('专业保洁', '/appointment/comm','1')
            "        icon="http://ygdjonline.com/ywxt/sun_miniapp/image/20230412/8422d38e-1969-48a9-a598-f781d7db38ea.png" text="保洁" />
              <van-grid-item 
                       @click="
              jmpPage('医院陪护', '/appointment/comm','6')
            "
              icon="http://ygdjonline.com/ywxt/sun_miniapp/image/20230411/577492c5-4236-4ab9-b571-20edd3fc3b4d.png" text="医陪" />
              <van-grid-item 
              @click="
              jmpPage('托育中心', '','')
            "
              icon="http://ygdjonline.com/ywxt/sun_miniapp/image/20230411/d927c93b-eb91-4a41-a818-69a66eaed592.png" text="养老" />
              <van-grid-item
                          @click="
              jmpPage('托育中心', '','')
            "
               icon="http://ygdjonline.com/ywxt/sun_miniapp/image/20230411/ee48060c-427a-4f00-8c11-9ecc524d81cc.png" text="育婴" />

              <van-grid-item 
                          @click="
              jmpPage('托育中心', '','')
            "
              icon="http://ygdjonline.com/ywxt/sun_miniapp/image/20230411/87f23729-78a3-477a-b025-18fb3a83996d.png" text="阳光商城" />
              <van-grid-item
                    @click="
                    jmpPage('水泳中心', '/appointment/comm','4')
                    "
               icon="http://ygdjonline.com/ywxt/sun_miniapp/image/20230411/9c7b2424-caf1-40dc-8651-7ad794711e9a.png" text="水泳中心" />
              <van-grid-item 
              @click="
              jmpPage('托育中心', '','')
            "
              icon="http://ygdjonline.com/ywxt/sun_miniapp/image/20230411/9efc6588-0a4e-402a-ac6b-28eedadf9169.png" text="托育中心" />
              <van-grid-item 
               @click="
              jmpPage('月子中心', '/appointment/comm','3')
            "
              icon="http://ygdjonline.com/ywxt/sun_miniapp/image/20230411/c3b16b6e-1e6e-47fd-b6e8-9eac3765bcc9.png" text="月子中心" />
            </van-grid>
          </div>

          <div class="rxcss" >
          <div style="margin-left:1.3rem;margin-top:1.0rem;" >
              <van-image
                width="30"
                height="30"
                src="https://ygdjonline.com/ywxt/sun_miniapp/image/20230421/3443f663-623e-498a-bcff-b138ec9f8f1d.png"
              /> 
          </div>
          <div style="width:55%;margin-left:15px;margin-top:11px;" >
              <van-image
                src="https://ygdjonline.com/ywxt/sun_miniapp/image/20230425/4c33f240-ef56-44ca-861e-9d57ea162338.png"
              /> 
          </div>

          </div>

      </div>
      <div style="color:rgba(0, 0, 0, 1);font-weight:600;margin-top:12px;margin-left:10px;" >热销推荐 </div>
      <div style="padding:5px;" >
          <div style="height:15px;" ></div>

          <div v-for="(item, index) in hotList" :key="index"  style="display:flex;border-radius:7px;box-shadow:rgba(94, 94, 94, 0.13) 1px 1px 2px 2px;" >
              <div style="width:30%;padding:10px;" >
                <img
                  style="border-radius:8px;width:95%;"
                  :src="item.img"
                />
              </div>
              <div style="width:65%;" >
                <div style="margin-top:0.9rem;" > 
                  <div style="color:rgba(0, 0, 0, 1);font-weight:600;" >{{item.title}}</div>
                  <div style="margin-top:10px;color:rgba(255, 94, 94, 1);" > {{item.phone}} </div>
                </div>
                <div style="line-height:3.6rem;float:right;margin-right:15px;" >  

                  <van-button round style="height:20px;" color="rgba(255, 94, 94, 1)"  @click=jmpPage(item.title,item.path,item.num)  type="info">立即预约</van-button>
                </div>
              </div>
          </div>


      </div>
    <div style="color:rgba(0, 0, 0, 1);font-weight:600;margin-top:12px;margin-left:10px;" >新闻资讯 </div>  
    <div style="height:10px;" ></div>
    <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoadList"
      >
      <ul style="border-top: 2px solid #e5e5e5;padding-bottom: 20px;" >
				<li class="likys"  v-for="item in list" :key="item.informationId">
					<div class="subject">{{item.title}}</div>
					<div class="icon">
						<div v-for="(card,i) in item.informationImgList" :key="i"
							style="width:30%;margin:5px;">
              <van-image :src="card"   height="5rem"  />
						</div>
					</div>
					<div class="text">
						<img class="bxoimg" :src="img20" alt="">
						<div class="name">阳光资讯</div>
						<div class="desc">{{item.createTime}}</div>
					</div>
				</li>
			</ul>
      </van-list>
      
  </div>

</template>

<script>
import config from '@/common/config'
import axios from 'axios'
import {getBannerList,queryList} from '@/common/api.js'
import { Toast } from 'vant';
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      images: [],
      remoteImgPath: config.remoteImgPath,
      imgPathUrl: config.imgPathUrl,
      picUrl: config.picUrl,
			ossUrl: config.ossUrl,
      list: [],
      loading: false,
      finished: false,
      img20: `${config.remoteImgPath}20.png`,
      pageNum:1,
      hotList:[{title:"首席技师月嫂+专业指导师",path: '/appointment/nanny',phone:"0531-82903328",img:"https://ygdjonline.com/ywxt/sun_miniapp/image/20230331/eb6fa2c1-2252-4726-a60d-afb8418805e0.jpg"},
      {title:"家务服务",path: '/appointment/comm',num:'2',phone:"0531-82903308",img:"https://ygdjonline.com/ywxt/sun_miniapp/image/20230331/1bfbe2a6-baea-4de4-b15f-dd19b074c069.jpg"},
      {title:"医院陪护",phone:"0531-82903508",img:"https://ygdjonline.com/ywxt/sun_miniapp/image/20230331/baf3baf5-fbae-4c75-86b7-2cb3f1a82787.jpg"},
      {title:"日常清洁",path: '/appointment/comm',num:'1',phone:"0531-82903378",img:"https://ygdjonline.com/ywxt/sun_miniapp/image/20230331/57108457-e919-4c82-9d56-edaf21db7442.jpg"},
      {title:"精致玻璃清洗",path: '/appointment/comm',num:'1',phone:"0531-82903378",img:"https://ygdjonline.com/ywxt/sun_miniapp/image/20230331/e364beb0-dc82-4fc0-9e32-9d426059e529.jpg"}
      ]
    };
  },
  mounted(){
      this.loadBanner();
      this.loadHotProductList();
  },
  methods:{
      loadBanner(){
          getBannerList().then(response => {
            this.images = (response.data.data || response.data || []).map(d => {
							if (d.bannerImageUrl.includes('ywxt')) {
								return {
									...d,
									bannerImageUrl: this.ossUrl + d.bannerImageUrl
								}
							}
							return {
								...d,
								bannerImageUrl: this.picUrl + d.bannerImageUrl
							}
						});
          });
      },
      onLoadList(){

        
        setTimeout(() => {
          if (this.refreshing) {
            this.list = [];
            this.refreshing = false;
          }


          queryList(this.pageNum).then(res => {
                console.log(res)
                for (let i = 0; i < res.data.list.length; i++) {
                  this.list.push({
                    ...res.data.list[i],
                    createTime: this.formatTime(res.data.list[i].createTime),
                    informationImgList: (res.data.list[i].informationImgList || []).map(d => {
                      if (d.includes('ywxt')) {
                        return this.ossUrl + d
                      }
                      return this.picUrl + d
                    })
                  });
                }
                this.loading = false;
                this.pageNum++;
                if (this.list.length >= res.total) {
                  this.finished = true;
                }
          });
          //let maps= {informationId:'1',createTime:'2023-01-12 12:123:12',informationImgList:['https://img01.yzcdn.cn/vant/apple-1.jpg'],title:'阳光大姐|新年里来自耄耄老人的一封'}


        }, 1000);
      },
      loadHotProductList(){
          console.log(1111111);
          axios.post("https://testwxmp.ygdjonline.com/productHotController/getProductHotInfo/1297",{"storeId": 1297, "pageSize": 20, "pageNum": 1}).then(function(resp){
              console.log(resp);
          }
          ).catch(function(error){
              console.log(error)
          }
          )
      },
      jmpPage(name,url,type){
          console.log(name);
          if(url == '' || url == undefined){
              Toast('板块暂未开放,请移至小程序查看！');
              return;
          }
          this.$router.push({path:url,query:{title:name,type:type}})
      },
      formatTime (d) {
        let date = new Date(d);//如果date为13位不需要乘1000
        if (typeof d == 'string') {
            return d.slice(0, 19).replace('T', ' ')
            // date = new Date(d.slice(0, 10).replace(/\-/g, "/"))
        }
        const Y = date.getFullYear() + '-';
        let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
        let D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate()) + ' ';
        let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
        let m = (date.getMinutes() <10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
        let s = (date.getSeconds() <10 ? '0' + date.getSeconds() : date.getSeconds());
        return Y+M+D+h+m+s;
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  scoped>
.rxcss {
    border-radius:4px;
    display:flex;
    background-image: linear-gradient(to right, rgba(255, 61, 61, 1), rgba(255, 83, 31, 1));
    margin-left:1%;
    width:98%;
    height: 65px;
  }
.containers {
  

  .active {
    display: none !important;
  } 
  .share-action-container {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    .black-page {
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }

    .content-container {
      position: absolute;
      bottom: 0;
      background-color: #fff; 
      width: 100%;
    }

    .share-img {
      width: 100rpx;
      height: 100rpx;
    }

    .share-item {
      text-align: center;
      font-size: 38rpx;
    }

    .share-content {
      display: flex;
      margin-top: 50rpx;
      margin-bottom: 20rpx;
      justify-content: space-around;
    }

    .share-title {
      display: flex;
      justify-content: space-between;
      padding: 10rpx 30rpx;
    }

    button {
      background-color: #fff;
      padding: 0;
      margin: 0;
      line-height: 60rpx;
    }

    button::after {
      border: none;
    }

    input {
      outline: none;
      border: none;
      list-style: none;
    }
  }

  .collection-view {
    display: flex;
    justify-content: flex-start;
    flex-flow: wrap row;
    align-items: flex-start;
    width: 100%;
  }
  .collection-item {
    display: inline-block;
    margin: 0;
    width: 25%;
    height: 70px;
    background-color: #f4f4f4;
    box-sizing: border-box;
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
  }

  .collection-item image {
    display: block;
    margin-left: 23px;
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
    width: 46px;
    height: 46px;
  }

  .collection-item p {
    display: block;
    height: 20px;
    line-height: 12px;
    text-align: center;
    font-size: 12px;
    color: #333;
  }

  .share-container {
    position: fixed;
    right: 0;
    top: 50%;
    width: 70rpx;
    height: 60rpx;
    padding: 10rpx;
    border-bottom-left-radius: 100rpx;
    border-top-left-radius: 100rpx;
    border: solid 2px #f9c6e3;
    border-right: none;
    background-color: #f9c6e3;

    .share-img {
      width: 60rpx;
      height: 60rpx;
    }
  }


}
  .type {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

    .stripe-card {
      width: 100%;
      height: 130rpx;
      margin: 8rpx 0;

      img {
        width: 100%;
        height: 130rpx;
        margin: 8rpx 0;

        image {
          width: 100%;
          height: 100%;
          border-radius: 100rpx;
        }
      }
    }
    .card {
      width: 25%;
      height: 270rpx;
      box-sizing: border-box;
      border: 1px solid #f4f4f4;
      image {
        width: 100%;
        height: 100%;
      }
    }
    .subject {
            text-align:left;
						font-size: 1.1rem;
						color: #353535;
		}
    .icon {
			display: flex;

			img {
				width: 100%;
				height: 100%;
			}
			img:first-child {
				margin-left: 0;
			}

			img:last-child {
				margin-right: 0;
			}
		}
    .text {
						font-size: 24rpx;
						display: flex;
						padding-top: 10rpx;

					}
          .bxoimg {
							width: 1rem;
							height: 1rem;
							margin-right: 10px;
							margin-top: 3px;

						}
          .name {
							flex: 1;
							color: #e64340;
							font-size: 0.5rem;
              text-align:left;
						}
            .desc {
							flex: 1;
							text-align: right;
							color: #888888;
							font-size: 0.5rem;
						}
    .likys{
          margin: 0 12px;
					border-bottom: 2px solid #e5e5e5;
					padding: 10px 0;
    }
</style>
