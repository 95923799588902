import request from '@/common/request'


export function getCode() {
    return request ({
      url: '/openApi/captcha',
      method: 'get',
      responseType:'blob'
    })
}

export function getBannerList() {
  return request ({
    url: '/comm/queryBannerList?storeId=12343',
    method: 'get'
  })
}

export function smsSenderCode(data) {
  return request ({
    url: '/openApi/smsSenderCode?code='+data.code+'&phone='+data.phone,
    method: 'post'
  })
}

export function createOrderWebpage(data) {
  return request ({
    url: '/openApi/createOrderWebpage',
    method: 'post',
    data
  })
}

export function getGoodsList(data) {
  return request ({
    url: '/openApi/getGoodsList?pageNum=1&pageSize=100&sortType='+data.sortType+'&miniserviceId='+data.miniserviceId,
    method: 'get'
  })
}

export function addHire(data) {
  return request ({
    url: '/openApi/addHire?smsCode='+data.smsCode,
    method: 'post',
    data
  })
}

export function queryList(pageNum) {
  return request ({
    url: '/information/queryList?storeId=12343&pageNum='+pageNum,
    method: 'get',
  })
}

export function getStoreList(data) {
  return request ({
    url: '/goods/getStoreList?province='+ data.province+'&city='+data.city+'&zone='+data.zone+'&type='+data.type,
    method: 'get',
  })
}

export function getProductHotInfo(data) {
  return request ({
    url: '/productHotController/getProductHotInfo/1297',
    method: 'post',
    data,
    headers:'VUE_UNI_URL_TWO'
  })
}